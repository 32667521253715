* {
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

:root {
  --blue-text: #002c44;
  --gray-text: #65686c;
  --red-text: #d72e3f;
  --red-hover: #db2d40;
}

/* @media(max-width: 1080px) {
  html {
      font-size: 93.75%;
  }
}

@media(max-width: 720px) {
  html {
      font-size: 87.5%;
  }
} */

.container-geral {
  width: 100%;
  display: flex !important;
  max-width: 1920px;
}

.obras .slick-next::before, .slick-prev::before {
  content: none;
}

.obras .slick-next, .slick-prev {
  z-index: 1;
}

.obras .slick-prev {
  left: 16px;
}

.obras .slick-next {
  right: 16px;
}



.container-full {
  width: 100%;
  justify-content: center;
  display: flex !important;
}

.container-fluid {
  display: flex;
  width: 100%;
  max-width: 1234px;
  margin: 0;
  padding: 0;
}

.grid-cont {
  display: flex;
  width: 100%;
  max-width: 1140px;
}
/* @media (max-width: 1200px) {
  .container-geral {
    padding: 0 22px;
  }
} */

@media (max-width: 1000px) {
  .grid-cont {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media (max-width: 700px) {
  .galeryCarousel button {
    z-index: 1;
  }
  .galeryCarousel .slick-next {
    right: 12px !important;
  }

  .galeryCarousel .slick-prev {
    left: 12px !important;
  }
}

.carMobItem .slick-list {
  padding-left: 0 !important;
}

.galeryCarousel .slick-list {
  padding: 42px 0 42px;
}

.propertyDetail .slick-list {
  padding-right: 0 !important;
}

.propertyDetail2 {
  width: 50%;
}

.Toastify__toast-container {
  width: 400px !important;
  height: 40px !important;
  -webkit-transform: skew(25deg) !important;
  transform: skew(25deg) !important;
}

.Toastify__toast-container--top-right {
  right: 1.5rem !important;
}

.Toastify__toast-body {
  -webkit-transform: skew(-25deg) !important;
  transform: skew(-25deg) !important;
  text-align: center !important;
}

.Toastify__toast {
  border-radius: 0 !important;
}

.Toastify__toast--success {
  background: -webkit-gradient(linear,left top,right top,from(#002e47),to(#0c6392)) !important;
}

.Toastify__toast--error {
  background: #d72e3f;
}

@media (max-width: 1350px) {
  .container-geral {
    width: 100%;
    display: flex !important;
    max-width: 1336px;
  }
}
